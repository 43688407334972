/*!
 *  - index.js
 * @generated Wed May 18 2022 09:52:21 GMT+0900 (GMT+09:00)
 */
'use strict';

(function ($, FUNCTIONS, cssua) {
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		mainSlide: function mainSlide() {},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			// _this.mainSlide();
		}
	};

	$(function () {
		return FUNCTIONS.loadDelayScript();
	});
})(window.jQuery, window.FUNCTIONS, window.cssua);